import moment from 'moment';
/* eslint-disable class-methods-use-this */

export default class WebServices {
  api;

  crud;

  totalPages = 0;

  totalResults = 0;

  currentPage = 1;

  perPage = 0;

  sorts;

  canPaginate = true;

  urlParams;

  waitingForRespond = false;

  dataIndex = 'items';

  totalResultsIndex = 'totalItemCount';

  itemsPerPageIndex = 'itemNumberPerPage';

  currentPageIndex = 'currentPageNumber';

  activityName = '';

  company = '';

  abortController;

  currentLang = 'labelFR';

  /**
   *
   * @param {Array} crud An array contains CRUD  links.
   * @param {*} api The Api to be used to handle requests/ By default the Data source will use an axios interface to handle reqquests.
   * @param {String} totalResultsIndex The index used to access the total found items number.
   * @param {String} itemsPerPageIndex The index used to access the items per page number.
   */
  constructor(crud, api, canPaginate, activityName, company, totalResultsIndex, itemsPerPageIndex) {
    this.api = api;
    this.crud = crud;
    this.abortController = new AbortController();
    this.company = company;
    this.urlParams = new URLSearchParams();
    this.canPaginate = canPaginate;
    this.sorts = new Map();
    this.activityName = activityName;
    this.currentLang = localStorage.getItem('userLang') ?? 'labelFR';
  }

  removeSort(col) {
    this.sorts.delete(col);
    this.urlParams.delete(`order_${col}`);
  }

  createUrlParams(sort, filter) {
    this.urlParams = new URLSearchParams();
    let parsedLocalStoredFilters = filter;
    const storedFilterData = localStorage.getItem(this.activityName);

    // load local storage filters
    if (storedFilterData !== '{}' && storedFilterData) {
      parsedLocalStoredFilters = JSON.parse(storedFilterData);
      Object.assign(parsedLocalStoredFilters, filter);
    }
    /// /
    if (sort !== null && sort.sense !== '' && sort.sense) {
      this.sorts.clear();
      this.sorts.set(sort.col, sort.sense);
    }
    // remove sort
    if (sort !== null && sort.sense === '' && sort.sense) {
      this.removeSort(sort.col);
    }
    /// /
    if (this.sorts.size > 0) {
      this.sorts.forEach((v, k) => this.urlParams.set(`order_${k}`, v));
    }
    if (typeof parsedLocalStoredFilters !== 'undefined' && parsedLocalStoredFilters != null) {
      if (this.activityName) {
        localStorage.setItem(this.activityName, JSON.stringify(parsedLocalStoredFilters));
      }
      Object.entries(parsedLocalStoredFilters).forEach((f) => this.urlParams.set(f[0], f[1]));
    }
    if (parsedLocalStoredFilters === null) {
      this.urlParams = new URLSearchParams();
    }
    if (this.company !== null && this.company) {
      this.urlParams.append('company', this.company);
    }
  }

  configPagination(data) {
    this.perPage = data[this.itemsPerPageIndex];
    this.currentPage = data[this.currentPageIndex];
    this.totalResults = data[this.totalResultsIndex];
    this.totalPages = this.totalResults / this.perPage;
  }

  async get(page = 1, sort = null, filter = {}, extras = undefined) {
    const url = `${this.crud.get ?? ''}${extras ?? ''}?page=${page}${
      !sort ? '&order_id=DESC' : sort
    }`;
    let fetchedData;
    this.createUrlParams(sort, filter);
    try {
      const { data } = await this.api.get(url, {
        params: this.urlParams,
      });
      fetchedData = 0;
      this.configPagination(data);
      fetchedData = data[this.dataIndex] ?? data;
    } catch (Error) {
      fetchedData = `There was an error feaching ${Error}`;
    }
    return fetchedData;
  }

  async update(id, UpdatedData) {
    try {
      await this.api.put(`${this.crud.update}/${id}`, UpdatedData);
      return true;
    } catch (err) {
      if (err.response.status === 404) {
        return "Vous n'êtes pas autorisé";
      }
      return err;
    }
  }

  async create(newData) {
    this.waitingForRespond = true;
    try {
      await this.api.post(this.crud.create, newData);
      this.waitingForRespond = false;
      return true;
    } catch (Error) {
      this.waitingForRespond = false;
      return Error?.response?.data?.errors?.error;
    }
  }

  async getUser(uId) {
    try {
      const response = await this.api.get(`/users/${uId}`);
      return response;
    } catch (err) {
      return err;
    }
  }

  async delete(id) {
    try {
      await this.api.delete(`${this.crud.delete}/${id}`, {
        params: { company: this.company },
      });
      return true;
    } catch (err) {
      return err.response?.data?.errors?.error ?? err;
    }
  }

  async bulkDelete(ids) {
    try {
      await this.api.post(`${this.crud.delete}/multiple-delete`, ids);
      return true;
    } catch (err) {
      return false;
    }
  }

  async getSalesAndClosed(page = 1, sort = null, filter = {}, nbElems = 10) {
    this.createUrlParams(sort, filter);
    const sales = [];

    try {
      const { data } = await this.api.get(
        `/transformation/?page=${page}&nbElems=${nbElems}${
          !sort || sort === null || sort?.sense === '' ? '&order_date=DESC&order_id=DESC&salesClosed=' : ''
        }`,
        {
          params: this.urlParams,
        },
      );

      /* eslint-disable-next-line no-restricted-syntax */
      for (const trans of data.items) {
        // there is just one stock in
        const stIn = trans.type.enumType === 'CORRECTION_STOCK_PRODUCTION' ? trans.movements[0] : trans.movements.find((mv) => mv.amount <= 0);
        const d = moment(new Date(trans.date));
        let textileGenesisStatus = null;
        try {
          /* eslint-disable-next-line no-await-in-loop */
          const tgResponse = await this.api.get(`/tg-sale/tg-sale-by-transformation/${trans.id}`);
          if (tgResponse.data) {
            textileGenesisStatus = tgResponse.data.tgStatus;
          }
        } catch (err) {
          console.error(`There was an error processing transformation ${trans.id}:`, err);
        }

        trans.movements.forEach((mov) => {
          const row = {
            date: d.format('MM/YYYY'),
            type: trans.type.translation[this.currentLang],
            inStock: '',
            surface: '',
            inQuan: '',
            outStock: '',
            price: '',
            outQuan: '',
            tgStatus: textileGenesisStatus,
            client: trans.client,
          };

          row.id = trans.id;
          if (stIn !== undefined) {
            // stockIn
            row.inStock = `${stIn.account.year} - ${stIn.account.region.country.translation[this.currentLang]} - ${stIn.account.product.translation[this.currentLang]}`;
            row.inQuan = Number(stIn.amount) * -1;
            row.surface = stIn.surface;
            row.price = mov.price;
            sales.push(row);
          }
        });

        if (trans.type.enumType === 'CLOSE') {
          const rowClose = {
            date: d.format('MM/YYYY'),
            type: trans.type.translation[this.currentLang],
            inStock: trans.type.translation[this.currentLang],
            surface: '',
            inQuan: '/',
            outStock: '',
            price: '/',
            outQuan: '',
            typeId: 7,
            client: trans.client,
          };
          rowClose.id = trans.id;

          sales.push(rowClose);
        }
      }
      this.perPage = data[this.itemsPerPageIndex];
      this.currentPage = data[this.currentPageIndex];
      this.totalResults = data[this.totalResultsIndex];
      this.totalPages = this.totalResults / this.perPage;
      return sales;
    } catch (err) {
      return `There was an error ${err}`;
    }
  }

  async getTransformation(page = 1, sort = null, filter = {}) {
    this.createUrlParams(sort, filter);
    const transformations = [];
    try {
      const { data } = await this.api.get(
        `/transformation/?page=${page}${
          !sort || sort === null || sort?.sense === '' ? '&order_date=DESC&order_id=DESC' : ''
        }`,
        {
          params: this.urlParams,
        },
      );
      data.items.forEach((trans) => {
        // there is only one stock in
        const transformation = [];
        const stIn = trans.type.enumType === 'CORRECTION_STOCK_PRODUCTION' || trans.type.enumType === 'HARVEST' || trans.type.enumType === 'CORRECTION_STOCK'
          ? trans.movements[0]
          : trans.movements.find((mv) => mv.amount <= 0);
        //
        const d = moment(new Date(trans.date));
        let totalStockInQuan = 0;
        let totalStockInSurf = 0;
        let totalStockOutQuan = 0;
        trans.movements.forEach((mov) => {
          const row = {
            id: '',
            transId: '',
            inStock: '',
            accountYear: '',
            surface: '',
            inQuan: 0,
            outStock: '',
            outQuan: 0,
          };
          row.id = mov.id;
          row.transId = trans.id;
          // stockOut
          if ((mov.amount > 0 || trans.type.enumType === 'CORRECTION_STOCK_PRODUCTION' || trans.type.enumType === 'CORRECTION_STOCK') && mov.account.product.enumType !== 'FLAX_STRAW') {
            // const quality = mov.account.quality?.translation[this.currentLang];
            row.outStock = mov.account.product.translation[this.currentLang];
            row.outQuan = mov.amount;
            // master totals
            totalStockOutQuan += Number(mov.amount);
          }
          // stock in
          if (stIn.account.product.enumType === 'FLAX_STRAW') {
            // master totals
            totalStockInQuan = Number(stIn.amount ?? 0);
            totalStockInSurf = Number(stIn.surface ?? 0);
            if (trans.type.enumType !== 'FIRST') {
              row.inStock = stIn.account.product.translation[this.currentLang];
              row.inQuan = stIn.amount;
              row.accountYear = `${mov.account.year} - ${mov.account.region.country.translation[this.currentLang]}`;
              row.surface = stIn.surface;
            }
          }
          if (row.inStock !== '' || row.outStock !== '') {
            transformation.push(row);
          }
        });
        // if there is a stock in => stock in name else it's straw
        const productName = stIn?.account?.product?.translation ? stIn?.account?.product.translation[this.currentLang] : 'Paille';
        const stockInString = stIn?.account.product.enumType.toLowerCase().includes('straw') || !stIn
          ? 'Paille'
          : productName;
        let stockOutString = '';
        stockOutString = productName === 'Paille' && trans.type.enumType !== 'FIRST' ? 'Paille' : 'Fibres';
        if (this.currentLang !== 'labelFR') {
          stockOutString = productName === 'Strovlas' && trans.type.enumType !== 'FIRST' ? 'Strovlas' : 'Vezels';
        }
        if (trans.type.enumType === 'CORRECTION_STOCK_PRODUCTION' || trans.type.enumType === 'CORRECTION_STOCK') {
          stockOutString = transformation[0].outStock;
        }
        transformations.push({
          id: trans.id,
          masterDetails: {
            date: d.format('MM/YYYY'),
            type: trans.type.translation[this.currentLang],
            inStock: stockInString,
            inQuan: totalStockInQuan,
            accountYear: stIn?.account?.year ?? '',
            outStock: stockOutString,
            outQuan: totalStockOutQuan,
            surface: totalStockInSurf,
          },
          subDetails: transformation,
        });
      });
      this.perPage = data[this.itemsPerPageIndex];
      this.currentPage = data[this.currentPageIndex];
      this.totalResults = data[this.totalResultsIndex];
      this.totalPages = this.totalResults / this.perPage;
      return transformations;
    } catch (err) {
      return `There was an error ${err}`;
    }
  }

  async exportCurrentTable(name, extras) {
    this.createUrlParams(null, {});
    const response = this.api.get(`${name ?? this.crud.get}export${extras ?? ''}`, {
      params: this.urlParams,
      method: 'GET',
      responseType: 'blob',
    });
    return response;
  }

  async pasteDataFromClipBoard(clipedText) {
    const response = await this.api.post('annual-surface-declarations/paste', {
      paste: clipedText,
      companyId: this.company,
    });
    return response;
  }

  async importFile(file) {
    const formData = new FormData();
    formData.append('file', file[0]);
    const response = await this.api.post(
      `annual-surface-declarations/import?companyId=${this.company}`,
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
      },
    );
    return response;
  }

  // get filters from the storage

  getFiltersFromStorage = (index, activityName) => {
    if (localStorage.getItem(activityName) && localStorage.getItem(activityName) !== '{}') {
      return JSON.parse(localStorage.getItem(activityName))[index];
    }
    return undefined;
  };

  // get straw countries
  async getStrawCountries(year) {
    const response = await this.api.get(
      `annual-surface-declarations/countries?companyId=${this.company}&year=${year}`,
    );
    return response;
  }

  async getStrawRegions(year) {
    const response = await this.api.get(
      `annual-surface-declarations/regions?companyId=${this.company}&year=${year}`,
    );
    return response;
  }
  // get client region

  async getClientRegion(countryEnum) {
    const response = await this.api.get(`regions/client-regions/?name=${countryEnum}`);
    return response;
  }
  // get parameters

  async getParams() {
    const response = await this.api.get('/parameters/');
    return response;
  }

  // update parameters

  async updateIntervals(id, changedParam) {
    try {
      await this.api.put(`/product-interval/${id}`, changedParam);
      return true;
    } catch (err) {
      return Error?.response?.data?.errors?.error;
    }
  }

  async updateParams(changedParam) {
    try {
      await this.api.put(`/parameters/${changedParam.pId}`, { value: changedParam.val });
      return true;
    } catch (err) {
      return Error?.response?.data?.errors?.error;
    }
  }

  // Update TG status

  async updateAllPendingTgSalesStatus() {
    try {
      await this.api.put('/tg-sale/update-tg-sales/');
      return true;
    } catch (err) {
      return Error?.response?.data?.errors?.error;
    }
  }

  // get transformation types

  async getTransformationTypes() {
    const response = await this.api.get('/transformation-type/');
    return response;
  }

  // get available years

  async getAvailableYears() {
    const response = await this.api.get(
      `/annual-surface-declarations/years?companyId=${this.company}`,
    );
    return response;
  }

  // get companies

  async getCompany(enumType, page = 1, sort = null) {
    this.createUrlParams(sort);
    const { data } = await this.api.get('/company-type/');
    const { id } = data.find((type) => type.enumType === enumType);
    const response = await this.api.get(`/company-info/?page=${page}&&companyType=${id}`, {
      params: this.urlParams,
    });
    this.perPage = response?.data[this.itemsPerPageIndex];
    this.currentPage = response?.data[this.currentPageIndex];
    this.totalResults = response?.data[this.totalResultsIndex];
    this.totalPages = this.totalResults / this.perPage;
    return response;
  }

  // get company by user

  async getCompanyByUser(id) {
    const response = await this.api.get(`company-info/${id}`);
    return response;
  }

  // get  company type

  async getCompanyType(enumType, page = 1) {
    const { data } = await this.api.get('/company-type/');
    const { id } = data.find((type) => type.enumType === enumType);
    return id;
  }

  //  get user - company

  async getUserCompany(page = 1, sort = null, filter = {}, extras = undefined) {
    const { data } = await this.api.get('/user-company');
    return data;
  }

  async getCompanyIdCurrentUser() {
    const { data } = await this.api.get('/user-company/company-by-current-user');
    return data;
  }

  // Account

  async getAccountRatio(accountId) {
    const response = await this.api.get(`/account/${accountId}/yield`);
    return response;
  }

  async getAllAccounts() {
    const response = await this.api.get(
      `/account/?company=${this.company}&order_product=ASC&order_year=DESC&order_region=ASC`,
    );
    return response;
  }

  async getAccountBalance(accountId) {
    const response = await this.api.get(`/account/${accountId}/balance`);
    return response;
  }

  // Stats

  async getAlldeclared(year) {
    const response = await this.api.get(
      `/annual-surface-declarations/total-declared?${year != null ? `year=${year}` : ''}&company=${
        this.company
      }`,
    );
    return response;
  }

  async getAllSells() {
    const response = await this.api.get(`/account/sales?company=${this.company}`);
    return response;
  }

  async getAllBalances() {
    const response = await this.api.get(`/account/balances?company=${this.company}`);
    return response;
  }

  async getBalancesDetails() {
    const response = await this.api.get(`/account/balances-details?company=${this.company}`);
    return response;
  }

  async getStatsHomePageDetails(startDate, endDate, type) {
    const sd = startDate ? `startDate=${startDate}` : '';
    const ed = endDate ? `&endDate=${endDate}` : '';
    const typeParam = type ? `&type=${type}` : '';
    const queryString = `${sd}${ed}${typeParam}`;
    const response = await this.api.get(`/stats/stats-homepage?${queryString}`);
    return response;
  }

  async getSurfaceStatsHomePageDetails(companyId, type) {
    const companyIdParam = companyId ? `companyId=${companyId}` : '';
    const typeParam = type ? `&type=${type}` : '';
    const response = await this.api.get(`/stats/surface-stats-homepage?${companyIdParam}`);
    return response;
  }

  async getScutchingActivitiesHomePageDetails(monthStart, fed) {
    const monthStartParam = `monthStart=${monthStart}`;
    const fedParam = fed ? `&fed=${fed}` : '';
    const response = await this.api.get(`/stats/scutching-activities-homepage?${monthStartParam}${fedParam}`);
    return response;
  }

  async getContributionsHomePageDetails(fed, startDate, endDate) {
    const fedParam = fed ? `fed=${fed}` : '';
    const startDateParam = startDate ? `&startDate=${startDate}` : '';
    const endDateParam = endDate ? `&endDate=${endDate}` : '';
    const response = await this.api.get(`/stats/contributions-homepage?${fedParam}${startDateParam}${endDateParam}`);
    return response;
  }

  async exportStatsPage(filters) {
    const response = this.api.get('/stats/stats-export', {
      params: filters,
      method: 'GET',
      responseType: 'blob',
    });
    return response;
  }

  async exportSurfacesStatsPage(companyId, type) {
    const response = this.api.get(`/stats/surfaces-stats-export?companyId=${companyId}&type=${type}`, {
      method: 'GET',
      responseType: 'blob',
    });
    return response;
  }

  async exportContributionsStatsHomePage(fed, startDate, endDate) {
    const fedParam = fed ? `fed=${fed}` : '';
    const startDateParam = startDate ? `&startDate=${startDate}` : '';
    const endDateParam = endDate ? `&endDate=${endDate}` : '';
    const response = await this.api.get(`/stats/contributions-stats-export?${fedParam}${startDateParam}${endDateParam}`, {
      method: 'GET',
      responseType: 'blob',
    });
    return response;
  }

  async getHarvestStats(statBy) {
    const response = await this.api.get(`/stats/harvest/${statBy}`);
    return response;
  }

  async getStockStats(fiberType) {
    const response = await this.api.get(`/stats/scutcher-homepage?fiberType=${fiberType}`);
    return response;
  }

  // Miscellaneous

  async getExchangeRateByDate(date) {
    const response = await this.api.get(`/money-parity/nearest?date=${date}`);
    return response;
  }

  async getQualities() {
    const response = await this.api.get('/quality/');
    return response;
  }

  async getLastDateTypeOperation(companyId, type) {
    const response = await this.api.get(`/transformation/${companyId}/${type}/last-operation`);
    return response;
  }

  async getLastDateCloseMonth(companyId, type) {
    const response = await this.api.get(`/transformation/date-close-month/${companyId}/${type}`);
    return response;
  }

  async getMultiscutchingCompanyName(userId) {
    const response = await this.api.get(`/users/multiscutching-company-name/${userId}`);
    return response;
  }

  async getMultiscutchingUsers() {
    const response = await this.api.get('/users/multiscutching-users');
    return response;
  }

  async getUsersByCompanyId(companyId) {
    const response = await this.api.get(`/users/users-by-company-id?companyId=${companyId}`);
    return response;
  }

  async downloadTrackingSalesTemplate(companyId, lang) {
    const response = this.api.get(`/transformation/download-tracking-sales-template?companyId=${companyId}&lang=${lang}`, {
      method: 'GET',
      responseType: 'blob',
    });
    return response;
  }

  async importSalesFile(file, lang) {
    const formData = new FormData();
    formData.append('file', file[0]);
    const response = await this.api.post(
      `transformation/import?companyId=${this.company}&lang=${lang}`,
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
        responseType: 'blob',
      },
    );

    return response;
  }
}
