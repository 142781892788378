import React, { Suspense, lazy, useContext } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';

import RequireNormalUser from 'components/RequireNormalUser';
import RequireAuth from 'components/RequireAuth';
import { RequireAdmin } from 'components/RequireAdmin';
import { Spin } from 'antd';
import { useAuth } from 'contexts/AuthContext';
import MainLayout from './MainLayout';
import NotFound from './NotFound';
import Transformation from './Transformation';
import Sell from './Sell';
import Unauthorized from './Unauthorized';
import AdminFragment from './AdminFragment';
import HomeAdmin from './HomeAdmin';
import PrivacyPolicy from './PrivacyPolicy';
import Reglement from './Reglement';
import MultiscutchingUserCompanyManagement from './MultiscutchingUserCompanyManagement';

const Home = lazy(() => import('./Home'));
const NewPassword = lazy(() => import('./NewPassword'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const Login = lazy(() => import('./Login'));
const AnnualSurfaces = lazy(() => import('./AnnualSurfaces'));
const Parameters = lazy(() => import('./Parameters'));
const UserManagement = lazy(() => import('./UserManagement'));
const NewUser = lazy(() => import('./NewUser'));
const FarmersManagement = lazy(() => import('./FarmersManagement'));
const ClientsManagaments = lazy(() => import('./ClientsManagement'));
const ScutchingManagement = lazy(() => import('./ScutchingManagement'));
const Import = lazy(() => import('./ImportPage'));
const ImportSales = lazy(() => import('./ImportSalesPage'));
const NewScutching = lazy(() => import('./NewScutching'));
function Router() {
  const { user } = useAuth();

  return (
    <BrowserRouter>
      <Suspense fallback={<Spin size="large" style={{ position: 'absolute', right: '50%', top: '50%' }} />}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin/" element={<RequireAuth><RequireAdmin><MainLayout /></RequireAdmin></RequireAuth>}>
            <Route index element={<HomeAdmin />} />
            <Route path="manage-scutching" element={<AdminFragment />}>
              <Route index element={<ScutchingManagement />} />
              <Route path="new-scutching" element={<NewScutching />} />
              <Route path="new-scutching/:sId/:federationId/:showAlert/:name" element={<NewScutching />} />
              <Route path="transformations/:sId/:federationId/:showAlert/:name" element={<Transformation />} />
              <Route path="surfaces/:sId/:federationId/:showAlert/:name" element={<AnnualSurfaces />} />
              <Route path="sells/:sId/:federationId/:showAlert/:name" element={<Sell />} />
              <Route path="sells/:sId/:federationId/:showAlert/:name/declare/sells/clients" element={<ClientsManagaments />} />
              <Route path="sells/:sId/:federationId/:showAlert/:name/declare/sells/import/" element={<ImportSales />} />
              <Route path="multiscutching-users/:sId/:federationId/:showAlert/:name" element={<MultiscutchingUserCompanyManagement />} />
              <Route path="users/:sId/:federationId/:showAlert/:name" element={<UserManagement />} />
              <Route path="surfaces/:sId/:federationId/:showAlert/:name/declare/annual-surfaces/import/" element={<Import />} />
              <Route path="surfaces/:sId/:federationId/:showAlert/:name/declare/annual-surfaces/farmers/" element={<FarmersManagement />} />
              <Route path="reglement" element={<Reglement />} />
            </Route>
            <Route path="parameters" element={<Parameters />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="reglement" element={<Reglement />} />
            <Route path="parameters/param/:name" element={<Parameters />} />
            <Route path="parameters/manage-users" element={<UserManagement />} />
            <Route path="parameters/new-user" element={<NewUser />} />
          </Route>
          <Route path="/" element={<RequireAuth><RequireNormalUser><MainLayout /></RequireNormalUser></RequireAuth>}>
            <Route index element={<Home />} />
            <Route path="declare/annual-surfaces" element={<AnnualSurfaces />} />
            <Route path="declare/annual-surfaces/import" element={<Import />} />
            <Route path="declare/transformation" element={<Transformation />} />
            <Route path="declare/sells" element={<Sell />} />
            <Route path="declare/sells/import" element={<ImportSales />} />
            <Route path="parameters" element={<Parameters />} />
            <Route path="parameters/param/:name" element={<Parameters />} />
            <Route path="parameters/manage-users" element={<UserManagement />} />
            <Route path="parameters/new-user" element={<NewUser />} />
            <Route path="manage-farmers" element={<FarmersManagement />} />
            <Route path="manage-clients" element={<ClientsManagaments />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="reglement" element={<Reglement />} />
          </Route>
          <Route path="new-password/:token" element={<NewPassword />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="reglement" element={<Reglement />} />
          <Route path="forgot-password" element={<ForgotPassword />} />
          <Route path="unauthorized" element={<Unauthorized />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default Router;
